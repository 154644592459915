import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [sticky, setSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isService, setIsService] = useState(false);

    const location = useLocation(); // Hook to get the current location
    const phoneNumber = '+91-8123552278';

    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber.replace(/-/g, '')}`;
    };

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Set isService flag based on current path
    useEffect(() => {
        if (location.pathname.startsWith('/services')) {
            setIsService(true);
        } else {
            setIsService(false);
        }
    }, [location.pathname]);

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const isActive = (path) => location.pathname === path;

    const navItems = [
        { path: '/', name: 'Home' },
        { path: '/aboutUs', name: 'About Us' },
        { path: '/services', name: 'Services' },
        { path: '/clientele', name: 'Clientele' },
        { path: '/blogs', name: 'Blogs' },
        { path: '/careers', name: 'Careers' },
        { path: '/contactUs', name: 'Contact Us' },
    ];

    return (
        <div className="z-20 absolute w-full">
            <nav className={`${sticky ? 'bg-white border-b border-[#cdd1cf]' : ''} flex items-center justify-between py-1 px-5 fixed top-0 w-full z-10`}>
                <Link to="/">
                    <img
                        className="logo w-28"
                        src={
                            sticky
                                ? "https://ehsguruwebmediabucket.s3.us-east-1.amazonaws.com/Logos/WhiteBg.webp"
                                : "https://ehsguruwebmediabucket.s3.us-east-1.amazonaws.com/Logos/black+background.png"
                        }
                        alt="logo"
                    />
                </Link>
                
                <div className="max-[1187px]:flex min-[1187px]:hidden cursor-pointer flex flex-col space-y-1" onClick={toggleMenu}>
                    <div className={`${sticky ? 'bg-black' : 'bg-white'} w-[20px] h-[2px]`}></div>
                    <div className={`${sticky ? 'bg-black' : 'bg-white'} w-[20px] h-[2px]`}></div>
                    <div className={`${sticky ? 'bg-black' : 'bg-white'} w-[20px] h-[2px]`}></div>
                </div>

                {/* Sidebar */}
                <div
                    className={`fixed top-0 right-0 h-full bg-transparent backdrop-blur-lg shadow-lg z-20 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-700 w-1/2`}
                >
                    <div className="flex justify-end p-4">
                        <button onClick={toggleMenu} className="text-black text-3xl">X</button>
                    </div>

                    <div className="flex flex-col items-center space-y-4 py-5 mt-10">
                        {navItems.map((item, index) => (
                            <div key={index} className={`inline-block list-none m-3 text-lg ${isService && item.name === "Services" ? 'bg-web-blue text-white px-3 py-2 rounded-full cursor-pointer' : (isActive(item.path) ? 'bg-web-blue text-white px-3 py-2 rounded-full hover:text-white cursor-pointer' : '')}`}>
                            <Link to={item.path} onClick={toggleMenu} className="hover:cursor-pointer">
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                        <p className="text-center text-lg py-4">
                            <span onClick={handlePhoneClick} className="cursor-pointer">
                                {phoneNumber}
                            </span>
                        </p>
                        <Link onClick={toggleMenu} to = "/esg-compliance-lead-certification-course" className="relative bg-white text-black px-3 py-2 rounded-lg shadow-sm glow-box m-3">
                            ESG Compliance Lead Course
                            
                        <style>{`
                        @keyframes animateGlow {
                            0% {
                            background-position: 0% 50%;
                            }
                            100% {
                            background-position: 200% 50%;
                            }
                        }

                        .glow-box::after {
                            content: '';
                            position: absolute;
                            top: 15px;
                            left: 0;
                            right: 0;
                            z-index: -1;
                            height: 100%;
                            width: 100%;
                            transform: scale(0.9) translateZ(0);
                            filter: blur(15px);
                            background: linear-gradient(
                            to left,
                            #7bb9e7,
                            #2e86d3,
                            #276fa7,
                            #1680d8,
                            #1a80c8,
                            #1680d8,
                            #276fa7,
                            #2e86d3,
                            #7bb9e7
                            );
                            background-size: 200% 200%;
                            animation: animateGlow 1.25s linear infinite;
                        }
                        `}</style>
                    </Link>
                    </div>
                </div>
                
                <div className="flex items-center min-[1187px]:flex max-[1187px]:hidden">
                    <div className={`flex items-center ${sticky ? 'text-black' : 'text-white'}`}>
                        {navItems.map((item, index) => (
                            <div key={index} className={`inline-block list-none m-3 text-lg cursor-pointer ${isService && item.name === "Services" ? 'bg-web-blue text-white px-3 py-2 rounded-full cursor-pointer' : (isActive(item.path) ? 'cursor-pointer bg-web-blue text-white px-3 py-2 rounded-full hover:text-white' : '')}`}>
                                <Link to={item.path} className="hover:cursor-pointer">
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                         <Link to = "/esg-compliance-lead-certification-course" className={`relative  px-3 py-2 rounded-lg cursor-pointer ${isActive('/esg-compliance-lead-certification-course') ? 'bg-web-blue text-white':'bg-white text-black' } shadow-sm glow-box m-3`}>
                            ESG Compliance Lead Course
                        <style>{`
                        @keyframes animateGlow {
                            0% {
                            background-position: 0% 50%;
                            }
                            100% {
                            background-position: 200% 50%;
                            }
                        }

                        .glow-box::after {
                            content: '';
                            position: absolute;
                            top: 15px;
                            left: 0;
                            right: 0;
                            z-index: -1;
                            height: 100%;
                            width: 100%;
                            transform: scale(0.9) translateZ(0);
                            filter: blur(15px);
                            background: linear-gradient(
                            to left,
                            #7bb9e7,
                            #2e86d3,
                            #276fa7,
                            #1680d8,
                            #1a80c8,
                            #1680d8,
                            #276fa7,
                            #2e86d3,
                            #7bb9e7
                            );
                            background-size: 200% 200%;
                            animation: animateGlow 1.25s linear infinite;
                        }
                        `}</style>
                    </Link>
                    </div>

                    <div>
                        <p className={`text-lg ${sticky ? 'text-black' : 'text-white'}`}>
                            <span onClick={handlePhoneClick} className="cursor-pointer">
                                {phoneNumber}
                            </span>
                        </p>
                    </div>
                    
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
