import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../src/components/Navbar';
import Footer from '../src/components/Footer';
import ScrollToTop from '../src/components/ScrollToTop';

const Home = lazy(() => import('./pages/home'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/contactUs'));
const Clientele = lazy(() => import('./pages/clientele'));
const Career = lazy(() => import('./pages/Career'));
const Service = lazy(() => import('./pages/Service'));
const Blogs = lazy(() => import('./pages/Blogs'));
const FireAndLifeSafety = lazy(() => import('./pages/fire_and_life_safety'));
const Ergonomics = lazy(() => import('./pages/ergonomics'));
const LegalServices = lazy(() => import('./pages/LegalServices'));
const Ehs = lazy(() => import('./pages/ehs'));
const Esg = lazy(() => import('./pages/esg'));
const EgsCoursePage = lazy(() => import('./pages/EgsCoursePage'));
const Signup = lazy(() => import('./components/AdminPage/SignUpPage'));
const Login = lazy(() => import('./components/AdminPage/Login'));
const Dashboard = lazy(() => import('./components/AdminPage/Dashboard'));

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="min-h-screen">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/clientele" element={<Clientele />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/services" element={<Service />} />
            <Route path="/services/fire-and-life-safety" element={<FireAndLifeSafety />} />
            <Route path="/services/ergonomics" element={<Ergonomics />} />
            <Route path="/services/legal-services" element={<LegalServices />} />
            <Route path="/services/ehs" element={<Ehs />} />
            <Route path="/services/esg" element={<Esg />} />
            <Route path="/esg-compliance-lead-certification-course" element={<EgsCoursePage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;
