import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faSquareXTwitter, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    const phoneNumber = '+91-8123552278';
    const email = 'ehssolutions@ehsguru.com';

    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber.replace(/-/g, '')}`;
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <div className="bg-web-blue h-auto pt-10">
            <div className="flex flex-col md:flex-row sm:p-8 p-4  space-y-10 lg:space-y-0">
                {/* Logo and Contact Details */}
                <div className="flex flex-col space-y-2 sm:w-1/5 w-full sm:pt-20">
                    <img
                        className="w-[200px]"
                        alt="logo"
                        src="https://ehsguruwebmediabucket.s3.us-east-1.amazonaws.com/Logos/black+background.png"
                    />
                    <div className="sm:pt-20 pt-3 flex flex-col">
                        <h4 className="text-[#f7951e] pb-2 sm:text-2xl text-xl">Contact Details</h4>
                        <p className="text-lg">
                            <FontAwesomeIcon icon={faPhone} className="mr-2 text-white" />
                            <span onClick={handlePhoneClick} className="text-white cursor-pointer">
                                {phoneNumber}
                            </span>
                        </p>
                        <p onClick={handleEmailClick} className="text-lg cursor-pointer">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-white" />
                            <span className="text-white">{email}</span>
                        </p>
                    </div>
                </div>

                {/* Divider */}
                <div className="bg-[#dedcdc] w-[1px] h-auto mx-10 md:block  hidden"></div>

                {/* Office Locations */}
                <div className="sm:w-1/4 text-white flex flex-col sm:space-y-6 space-y-4">
                    <div className="flex flex-col space-y-2">
                        <p className="sm:text-2xl text-xl text-bold text-[#f7951e]">Corporate Office North</p>
                        <a href="https://maps.app.goo.gl/soaQMkURuywUZ5si7" target="_blank" rel="noopener noreferrer">
                            <div className="flex space-x-2 items-center leading-relaxed">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-white" />
                                <p>116-117, 1st Floor, Bestech Chambers, Sushant Lok, Phase 1, Sector 43, Gurugram, 122002, Haryana</p>
                            </div>
                        </a>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <p className="sm:text-2xl text-xl text-bold text-[#f7951e]">Regional Office West</p>
                        <a href="https://maps.app.goo.gl/5KRGvSLRrg7zKRAf7" target="_blank" rel="noopener noreferrer">
                            <div className="flex space-x-2 items-center leading-relaxed">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-white" />
                                <p>310-311, 3rd Floor, Crescent Business Pa, Andheri East, Mumbai - 400069</p>
                            </div>
                        </a>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <p className="sm:text-2xl text-xl text-bold text-[#f7951e] ">Regional Office South</p>
                        <a href="https://maps.app.goo.gl/5KRGvSLRrg7zKRAf7" target="_blank" rel="noopener noreferrer">
                            <div className="flex space-x-2 items-center leading-relaxed ">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-white" />
                                <p>T-5, Block B, Gayatri Ocean Blue, Kumarapalli, Whitefield, Bengaluru - 560066, Karnataka</p>
                            </div>
                        </a>
                    </div>
                </div>

                {/* Divider */}
                <div className="bg-[#dedcdc] w-[1px] h-auto mx-10 sm:block hidden"></div>

                
                <div className="flex lg:flex-row md:flex-col flex-col justify-between sm:pt-20 space-x-0 md:space-x-14 md:space-y-0 space-y-8">
                    <div className="flex sm:space-x-14 space-x-28">
                        <div className="flex flex-col space-y-6 text-web-bwhite">
                            <Link to="/aboutUs" className="hover:text-[#f7951e] transition-colors duration-200">
                                About Us
                            </Link>
                            <Link to="/careers" className="hover:text-[#f7951e] transition-colors duration-200">
                                Careers
                            </Link>
                            <Link to="/contactUs" className="hover:text-[#f7951e] transition-colors duration-200">
                                Contact Us
                            </Link>
                            <Link to="/services" className="hover:text-[#f7951e] transition-colors duration-200">
                                Services
                            </Link>
                            <Link to="/clientele" className="hover:text-[#f7951e] transition-colors duration-200">
                                Clientele
                            </Link>
                            <Link to="/blogs" className="hover:text-[#f7951e] transition-colors duration-200">
                                Blogs
                            </Link>
                        </div>

                        <div className="flex flex-col space-y-4">
                            <h1 className="text-[#f7951e]">Products</h1>
                            <Link className="text-white hover:text-[#f7951e] transition-colors duration-200 " to="https://ergotattva.com/">
                                ErgoTattva
                            </Link>
                            <Link className="text-white hover:text-[#f7951e] transition-colors duration-200" to="https://www.linkedin.com/company/ehsguru/">
                                RuleSutra
                            </Link>
                            <Link className="text-white hover:text-[#f7951e] transition-colors duration-200" to="/esg-compliance-lead-certification-course">
                                ESG Course
                            </Link>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-5 max-w-[260px]">

                        <div className="flex space-x-5 text-4xl text-white">
                            <a className="hover:text-[#f7951e] transition-colors duration-200" href="https://www.linkedin.com/company/ehsguru/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a className="hover:text-[#f7951e] transition-colors duration-200" href="https://www.youtube.com/@EHSGuru" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>

                            <a className="hover:text-[#f7951e] transition-colors duration-200" href="https://www.instagram.com/egss_2017?utm_source=qr&igsh=bHFkcmJlMTlwZmM5" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a className="hover:text-[#f7951e] transition-colors duration-200" href="https://www.facebook.com/profile.php?id=61553987835742" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a className="hover:text-[#f7951e] transition-colors duration-200" href="https://twitter.com/EHSGuru2017" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                <FontAwesomeIcon icon={faSquareXTwitter} />
                            </a>
                        </div>

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.606003610806!2d77.07541477416098!3d28.46129109183872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18ec33aec711%3A0xfae4ec8bbac92b40!2sEHS%20Guru%20Sustainable%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1735906653522!5m2!1sen!2sin"
                            width="100%" 
                            height="200px" 
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="EHS Guru Location"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-1 text-web-bwhite text-sm pb-5 select-none">
                <p>EHS Guru Sustainable Solutions. All Rights Reserved</p>
                <div className="flex space-x-8 text-[#f7951e]">
                    <p>Privacy Policy</p>
                    <p>Terms & Conditions</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
